import React, {useEffect} from "react";
import { useNavigate } from "react-router-dom";
import {useAuth0} from "@auth0/auth0-react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Navbar from "../login/Navbar";
import './TeamsTable.css';
import '../global.css';
import {getTeams} from "../api/teams";
import CircularProgress from "@mui/material/CircularProgress";
import styled from '@emotion/styled'

const Button = styled.button`
      padding: 5px;
      font-size: 15px;
      background-color: #1976d2;
      color: #e9ebf0;
      border: none;
      border-radius: 4px;
      &:hover {
        color: white;
      }
`

export type team = {
    id: number,
    name: string,
    wins: number,
    losses: number,
    ties: number,
    scored: number,
    conceded: number,
}

function getPoints(team: team) {
    return team.wins * 3 + team.ties;
}

function compare(a: team, b: team) {
    const aPoints = getPoints(a);
    const bPoints = getPoints(b);
    if (aPoints > bPoints) {
        return -1;
    }
    if (aPoints < bPoints) {
        return 1;
    }
    if (a.scored - a.conceded > b.scored - b.conceded) {
        return -1;
    }
    if (a.scored - a.conceded < b.scored - b.conceded) {
        return 1;
    }
    return 0;
}

export default function TeamsTable() {
    const {user, isAuthenticated, isLoading} = useAuth0();
    const [teams, setTeams] = React.useState<team[]>([]);
    const [loading, setLoading] = React.useState<boolean>(true);
    const navigate = useNavigate();

    useEffect(() => {
        const response = getTeams();
        response.then((teams) => {
            setTeams(teams);
            setLoading(false);
        });
    }, []);

    return (
        <div className={"page-container"}>
            <Navbar/>
            <Button onClick={() => navigate("/fixtures")}>Pregledaj rezultate</Button>
            {loading ? <div className="loader"><CircularProgress style={{"height": "100px", "width": "100px"}}/></div> :
                <TableContainer style={{"width": "70vw"}} component={Paper} className={"table"}>
                    <Table sx={{minWidth: 650}} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{"font-weight":"bold"}}>Team</TableCell>
                                <TableCell align="right" sx={{"font-weight":"bold"}}>Played</TableCell>
                                <TableCell align="right" sx={{"font-weight":"bold"}}>Wins</TableCell>
                                <TableCell align="right" sx={{"font-weight":"bold"}}>Draws</TableCell>
                                <TableCell align="right" sx={{"font-weight":"bold"}}>Loses</TableCell>
                                <TableCell align="right" sx={{"font-weight":"bold"}}>Goals</TableCell>
                                <TableCell align="right" sx={{"font-weight":"bold"}}>Points</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {teams.sort(compare).map((row) => (
                                <TableRow
                                    key={row.name}
                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.name}
                                    </TableCell>
                                    <TableCell align="right">{row.wins + row.ties + row.losses}</TableCell>
                                    <TableCell align="right">{row.wins}</TableCell>
                                    <TableCell align="right">{row.ties}</TableCell>
                                    <TableCell align="right">{row.losses}</TableCell>
                                    <TableCell align="right">{row.scored}:{row.conceded}</TableCell>
                                    <TableCell align="right">{getPoints(row)}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>}
        </div>
    );
};
