import {api} from "./paths";
import axios from "axios";
import {CommentFixt} from "../fixtures/Fixture";

export async function getFixtures() {
    const response = await axios.get(api.FIXTURES_URL);
    return response.data;
}

export async function getFixtureDetails(id: number) {
    const response = await axios.get(api.FIXTURES_URL + "/" + id);
    return response.data;
}

export async function getCommentsForFixture(id: number) {
    const response = await axios.get(api.FIXTURES_URL + "/" + id + "/comments");
    return response.data;
}

export async function addNewCommentForFixture(comment: CommentFixt, token: string) {
    const response = await axios.post(api.FIXTURES_URL + "/comments", comment,{
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });
    return response.data;
}

//write function to delete comment
export async function deleteComment(id: number, token: string) {
    const response = await axios.delete(api.FIXTURES_URL + "/comments/" + id, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });
    return response.data;
}

//write function to update comment
export async function updateComment(comment:CommentFixt, token: string) {
    const response = await axios.put(api.FIXTURES_URL + "/comments/" + comment.id, comment, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });
    return response.data;
}

//write function to update result of fixture
export async function updateFixture(data: any, token: string) {
    const response = await axios.put(api.FIXTURES_URL + "/result", data, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });
    return response.data;
}

axios.interceptors.response.use((response) => {
    return response;
}, (error) => { // Anything except 2XX goes to here
    const status = error.response?.status || 500;
    if (status === 401) {
        window.location.href = window.location.origin + "/error"
    } else {
        return Promise.reject(error); // Delegate error to calling side
    }
});