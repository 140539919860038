import React from 'react';
import {Route, BrowserRouter as Router, Routes} from "react-router-dom";
import Fixture from './fixtures/Fixture';
import Fixtures from './fixtures/Fixtures';
import Err from './table/Err';
import TeamsTable from './table/TeamsTable';

function App() {
    return (
        <div>
            <Router>
                <Routes>
                    <Route path="/" element={<TeamsTable/>} />
                    <Route path={"/fixtures"} element={<Fixtures/>} />
                    <Route path={"/fixtures/:id"} element={<Fixture/>} />
                    <Route path={"/error"} element={<Err />} />
                </Routes>
            </Router>
        </div>
    );
}

export default App;