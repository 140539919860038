import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

import styled from '@emotion/styled'

const Button = styled.button`
      padding: 5px;
      font-size: 15px;
      background-color: #e9ebf0;
      color: #1976d2;
      border: none;
      border-radius: 4px;
      &:hover {
        color: white;
      }
`

export default function LoginButton() {
    const { loginWithRedirect } = useAuth0();
    return (
        <Button onClick={() => loginWithRedirect()}>Log In</Button>
    );
};

