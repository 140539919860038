import { useAuth0 } from '@auth0/auth0-react';
import styled from '@emotion/styled';
import {Avatar, Dialog, DialogTitle, Grid } from '@mui/material';
import deepOrange from '@mui/material/colors/deepOrange';
import {Field, Formik } from 'formik';
import React from 'react';
import { deleteComment, updateComment } from '../api/fixtures';
import { CommentFixt } from './Fixture';
import './Fixture.css';

export type CommentProps = {   
    comment: CommentFixt;
    triggerReferesh: () => void;
    setLoading: (loading: boolean) => void;
}

const Button = styled.button`
      padding: 5px;
      font-size: 15px;
      background-color: #e9ebf0;
      color: #1976d2;
      border: none;
      border-radius: 4px;
      &:hover {
        color: white;
      }
`

export const isUserAdmin = (user: any) => {
    return user?.email == 'admin@admin.com';
}

const formatDate = (date: Date) => {
    return date.toString().split('T')[0] + ' ' + date.toString().split('T')[1].split('.')[0];
}
const reverseDateString = (date: string) => {
    return date.split('-').reverse().join('-');
}

const CommentFF = ({ comment, triggerReferesh, setLoading }: CommentProps) => {
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const {user, getAccessTokenSilently} = useAuth0();
    return (
        <>
        <Grid container wrap="nowrap" spacing={2}>
            <Grid item>
                <Avatar sx={{ bgcolor: deepOrange[500] }}>{comment.author![0]}</Avatar>
                {((user?.name == comment.author) || isUserAdmin(user)) && <div>
                 <Button onClick={async () => {
                    setLoading(true);
                    const token = await getAccessTokenSilently();
                    await deleteComment(comment.id!, token);
                    triggerReferesh();
                    setLoading(false);
                }}>Obriši</Button></div>}
                {user?.name == comment.author && <div>
                <Button onClick={async () => {
                    setDialogOpen(true);
                } }>Uredi</Button> </div>}
            </Grid>
            <Grid justifyContent="left" item xs zeroMinWidth>
                <h4 style={{ margin: 0, textAlign: "left" }}>{comment.author}</h4>
                <p style={{ textAlign: "left" }}>
                    {comment.text}
                </p>
                <p style={{ textAlign: "left", color: "gray" }}>
                    posted {formatDate(comment.created_at!)}
                </p>
            </Grid>
        </Grid>
        <Dialog sx={{
            "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "500px",  // Set your width here
                },
            },
        }} id={"dialog"} open={dialogOpen} onClose={() => setDialogOpen(false)}>
            <DialogTitle >Uređivanje komentara</DialogTitle>
            <Formik
                initialValues={{ text: comment.text, author: comment.author, created_at: comment.created_at, id: comment.id, fixture_id: comment.fixture_id }}
                onSubmit={async (values, actions) => {
                    setLoading(true);
                    const token = await getAccessTokenSilently();
                    await updateComment(values, token);
                    actions.resetForm();
                    triggerReferesh();
                    setDialogOpen(false);
                    setLoading(false);
                }}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      resetForm
                      /* and other goodies */
                  }) => (
                    <form onSubmit={handleSubmit} className={"form-container"}>
                        <Field component={"textarea"} id="text" name="text" type="text" placeholder="Komentar"/>
                        <Button type="submit" >Uredi komentar</Button>
                    </form>
                )}
            </Formik>
        </Dialog>    
        </>
    );
};

export default CommentFF;
