import React from 'react';
import {Fixture} from './Fixtures';
import Typography from '@mui/material/Typography';

export type ResultProps = {
    fixture: Fixture;
}

const Result = ({fixture}: ResultProps) => {
    return (<>
            <Typography
                variant="h6"
                component="div"
                sx={{flexGrow: 1, display: {xs: 'none', sm: 'block'}}}
            >
                Kolo: {fixture.ordinal}
            </Typography>
            <Typography
                variant="h4"
                component="div"
                sx={{flexGrow: 1, display: {xs: 'none', sm: 'block'}}}
            >
                {fixture.team1} - {fixture.team2} {fixture.team1_goals == undefined ? "Nije odigrano" : `${fixture.team1_goals} : ${fixture.team2_goals}`}
            </Typography>
        </>
    );
};

export default Result;
