import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Auth0Provider } from "@auth0/auth0-react";
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
    <Auth0Provider
        domain="dev-m020rsbr5kub4fxh.us.auth0.com"
        clientId="JLx0US7jsi6IPCgnMqNuNorJvqcYC73X"
        audience='https://nweb-be.onrender.com'
        // TODO: change redirect uri
        redirectUri={window.location.origin}
    >
        <App />
    </Auth0Provider>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
