import {useAuth0} from '@auth0/auth0-react';
import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import '../global.css';
import './Fixture.css'
import {Fixture} from './Fixtures';
import CircularProgress from "@mui/material/CircularProgress";
import Navbar from '../login/Navbar';
import {addNewCommentForFixture, getCommentsForFixture, getFixtureDetails, updateFixture} from '../api/fixtures';
import Result from './Result';
import CommentFF, { isUserAdmin } from './CommentFF';
import {Dialog, DialogTitle, Divider} from '@mui/material';
import {Field, Formik} from 'formik';
import styled from '@emotion/styled';

export type CommentFixt = {
    fixture_id: number;
    id: number | null;
    author: string | undefined;
    text: string;
    created_at: Date | null;
}

const Button = styled.button`
      padding: 5px;
      font-size: 15px;
      background-color: #1976d2;
      color: #e9ebf0;
      border: none;
      border-radius: 4px;
      &:hover {
        color: white;
      }
`
const blankComment: CommentFixt = {
    text: '', author: undefined, created_at: null, id: null, fixture_id: -1
}

//create compare function for sorting comments by date whose type is string
const compare = (a: CommentFixt, b: CommentFixt) => {
    const dateA = new Date(a.created_at!);
    const dateB = new Date(b.created_at!);
    return  dateB.getTime() - dateA.getTime();
}

const FixtureDetails = () => {
    const {id} = useParams();
    const [fixture, setFixture] = useState<Fixture>();
    const [comments, setComments] = useState<CommentFixt[]>([]);
    const {isAuthenticated, user, getAccessTokenSilently} = useAuth0();
    const [commentForSending, setCommentForSending] = useState<CommentFixt>({
        ...blankComment, fixture_id: fixture ? fixture.id : -1
    });
    const [triggerRefresh, setTriggerRefresh] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [DialogOpen, setDialogOpen] = useState<boolean>(false);

    useEffect(() => {
            const getFixture = async () => {
                const fixId = id == undefined ? 0 : parseInt(id);
                const fixture = await getFixtureDetails(fixId);
                setFixture(fixture);
            };
            const getComments = async () => {
                const fixId = id == undefined ? 0 : parseInt(id);
                const commentsFromDB = await getCommentsForFixture(fixId);
                setComments([...commentsFromDB]);
            }
            getFixture();
            getComments();
        }
        , [id, getAccessTokenSilently, triggerRefresh]);

    return (
        <div className={"page-container"}>
            <Navbar/>
            {fixture == undefined ?
                <div className="loader"><CircularProgress style={{"height": "100px", "width": "100px"}}/></div>
                :
                <Result fixture={fixture}/>
            }
            {isUserAdmin(user) && <Button onClick={() => setDialogOpen(true)}>{fixture?.team1_goals == undefined ? "Unesi rezultat" : "Promijeni rezultat"}</Button>}
            {isAuthenticated && <div className={"form-container"}>
                <Formik
                    initialValues={commentForSending}
                    onSubmit={async (values, actions) => {
                        const token = await getAccessTokenSilently();
                        await addNewCommentForFixture({...values, author:user!.name, fixture_id: fixture ? fixture.id : -1}, token);
                        setTriggerRefresh(!triggerRefresh);
                        actions.resetForm();
                    }}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                        resetForm
                          /* and other goodies */
                      }) => (
                        <form onSubmit={handleSubmit} className={"form-container"}>
                            <Field component={"textarea"} id="text" name="text" type="number" placeholder="Komentar"/>
                            <Button id="bt" type="submit" >Dodaj komentar</Button>
                        </form>
                    )}
                </Formik>
            </div>}
            {loading 
                ? <div className="loader"><CircularProgress style={{"height": "100px", "width": "100px"}}/></div> 
                : <div className={"comments-container"}>
                {comments && isAuthenticated && comments.sort(compare).map((comment: CommentFixt) => (
                    <>
                        <CommentFF comment={comment} setLoading={setLoading} triggerReferesh={() => setTriggerRefresh(!triggerRefresh)}/>
                        <Divider variant="fullWidth" style={{margin: "30px 0"}}/>
                    </>
                ))}
            </div>}
            <Dialog open={DialogOpen} onClose={() => setDialogOpen(false)}>
                <DialogTitle>Unesi rezultat</DialogTitle>
                <Formik
                    initialValues={{team1_goals: fixture?.team1_goals, team2_goals: fixture?.team2_goals}}
                    onSubmit={async (values, actions) => {
                        const token = await getAccessTokenSilently();
                        await updateFixture({...values, id: fixture?.id}, token);
                        setTriggerRefresh(!triggerRefresh);
                        setDialogOpen(false);
                        actions.resetForm();
                    }}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          resetForm
                          /* and other goodies */
                      }) => (
                        <form onSubmit={handleSubmit} className={"form-container"}>
                            <div>
                            <label htmlFor="team1_goals">{fixture?.team1}</label>
                            <input className={"inp"} type="nubmer" name="team1_goals" onChange={handleChange}></input>
                            </div>
                            <div>
                            <label htmlFor="team2_goals">{fixture?.team2}</label>
                            <input className={"inp"} type="nubmer" name="team2_goals" onChange={handleChange}></input>
                            </div>
                            <Button id="bt" type="submit" >Spremi rezultat</Button>
                        </form>
                    )}
                </Formik>
            </Dialog>
        </div>
    );
};

export default FixtureDetails;


