import React from 'react';
import Navbar from '../login/Navbar';
import '../global.css';

const Err = () => {
    return (
        <div className={"page-container"}>
            <Navbar />
            <div style={{"color":"red"}}>
                Nešto je pošlo po krivu :(
                <br/>
                Ukoliko ste prijavljeni, odjavite se i pokušajte ponovo.
                <br/>
                U protivnom se prijavite.
            </div>
        </div>
    );
};

export default Err;
