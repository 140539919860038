import React from 'react';
import { useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import LogoutButton from './LogoutButton';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { useAuth0 } from '@auth0/auth0-react';
import LoginButton from './LoginButton';
import '../global.css';

const Navbar = () => {
    const { isAuthenticated, user } = useAuth0();
    const navigate = useNavigate();
    
    return (
        <Box sx={{display: 'flex'}}>
            <AppBar component="nav">
                <Toolbar>
                    <Typography
                        variant="h6"
                        component="div"
                        className={'tablica-ime'}
                        sx={{flexGrow: 1, display: {xs: 'none', sm: 'block'}}}
                        onClick={() => navigate('/')}
                    >
                        Tablica HNL
                        {user && <span style={{color: 'white', fontSize: '15px'}}> - {user.name}</span>}
                    </Typography>
                    <Divider orientation="horizontal" flexItem/>
                    <Box sx={{display: {xs: 'none', sm: 'block'}}}>
                        {isAuthenticated ? <LogoutButton/> : <LoginButton/>}
                    </Box>
                </Toolbar>
            </AppBar>
        </Box>
    )
        ;
};

export default Navbar;
