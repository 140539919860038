import {useAuth0} from '@auth0/auth0-react';
import React from 'react';
import {css} from '@emotion/react'
import styled from '@emotion/styled'

const Button = styled.button`
      padding: 5px;
      font-size: 15px;
      background-color: #e9ebf0;
      color: #1976d2;
      border: none;
      border-radius: 4px;
      &:hover {
        color: white;
      }
`

export default function LogoutButton() {
    const {logout} = useAuth0();

    return (
        <Button onClick={() => logout({returnTo: window.location.origin})}>
            Log Out
        </Button>
    );
};

