import React, {useEffect} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {getFixtures} from '../api/fixtures';
import '../global.css';
import Navbar from '../login/Navbar';
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from 'react-router-dom';

export type Fixture = {
    id: number;
    team1_id: number;
    team2_id: number;
    team1: string;
    team2: string;
    team1_goals: number;
    team2_goals: number;
    ordinal: number;
}

const Fixtures = () => {
    const [fixtures, setFixtures] = React.useState<Fixture[]>([]);
    const [loading, setLoading] = React.useState<boolean>(true);
    const navigate = useNavigate();

    useEffect(() => {
            const response = getFixtures();
            response.then((fixtures) => {
                setFixtures(fixtures);
                setLoading(false);
            });
        }
        , []);

    return (
        <div className={"page-container"}>
            <Navbar/>
            {loading ? <div className="loader"><CircularProgress style={{"height": "100px", "width": "100px"}}/></div> :
                <TableContainer style={{"width": "70vw"}} component={Paper} className={"table"}>
                    <Table sx={{minWidth: 650}} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left" sx={{"font-weight": "bold"}}>Domaćin</TableCell>
                                <TableCell align="center" sx={{"font-weight": "bold"}}>Rezultat</TableCell>
                                <TableCell align="right" sx={{"font-weight": "bold"}}>Gost</TableCell>
                                <TableCell align="right" sx={{"font-weight": "bold"}}>Kolo</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {fixtures.map((fixture: Fixture) => (
                                <TableRow
                                    className={"fixture-row"}
                                    onClick={() => navigate("/fixtures/" + fixture.id)}
                                    key={fixture.id}
                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                >
                                    <TableCell align="left">{fixture.team1}</TableCell>
                                    <TableCell
                                        align="center">{fixture.team1_goals == undefined ? "nije odigrano" : `${fixture.team1_goals}:${fixture.team2_goals}`}</TableCell>
                                    <TableCell align="right">{fixture.team2}</TableCell>
                                    <TableCell align="right">{fixture.ordinal}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>}
        </div>
    );
};

export default Fixtures;
